<template>
  <div>
    <b-breadcrumb :items="breadcrumbItems"></b-breadcrumb>

    <h4 qid="maintenance-create-p-title">
      {{$t('generic_controls.create.title')}}
    </h4>

    <div class="card">
      <div class="card-body">
        <b-alert v-if="error.length"
                 show
                 variant="danger"
                 qid="create-p-error"
        >
          {{ error }}
        </b-alert>
        <b-form @submit="formSubmit" qid="create-p-form">
          <div class="form-container">
            <div class="row">
              <div class="col-sm-6 pl-5 pr-3">
                <b-form-group>
                  <label qid="create-p-name-label">
                    {{ $t('generic_controls.create.name_label') }}
                  </label>
                  <b-form-input
                    qid="create-p-form-name"
                    type="text"
                    v-model="pForm.name"
                    :maxLength="255"
                    required
                    :placeholder="$t('generic_controls.create.name_placeholder')"
                  ></b-form-input>
                </b-form-group>
                <b-form-group>
                  <label qid="create-p-description-label">
                    {{ $t('generic_controls.create.description_label') }}
                  </label>
                  <b-form-textarea
                    qid="create-p-form-description"
                    v-model="pForm.description"
                    rows="6"
                    max-rows="6"
                    :placeholder="$t('generic_controls.create.description_placeholder')"
                  ></b-form-textarea>
                </b-form-group>
                <b-form-group>
                  <label qid="create-do-tags-label">
                    {{ $t('processes.create.tags_label') }}
                  </label>
                  <multiselect
                      v-model="selectedTags"
                      :options="tags"
                      :multiple="true"
                      :select-label="$t('system.dropdown_select_label')"
                      :selected-label="$t('system.dropdown_selected_label')"
                      :deselect-label="$t('system.dropdown_deselect_label')"
                      :taggable="true"
                      track-by="name_translated"
                      :placeholder="$t('processes.create.tags_placeholder')"
                      label="name_translated"
                      qid="create-do-tags-select"
                      @search-change="loadTags"
                      @tag="addTag"
                  ></multiselect>
                </b-form-group>
              </div>
              <div class="col-sm-6 pl-5 pr-3">
                <b-form-group>
                  <label qid="create-p-users-label">
                    {{ $t('generic_controls.create.owners_label') }}
                  </label>
                  <multiselect
                      v-model="selectedUsers"
                      :options="users"
                      :multiple="true"
                      :taggable="false"
                      track-by="slug"
                      open-irection="below"
                      :placeholder="$t('generic_controls.create.owners_placeholder')"
                      label="name"
                      :select-label="$t('system.dropdown_select_label')"
                      :selected-label="$t('system.dropdown_selected_label')"
                      :deselect-label="$t('system.dropdown_deselect_label')"
                      @remove="removeUserFromDropdown"
                      @search-change="loadUsers"
                      qid="create-p-users-select"
                  >
                    <template slot="tag" slot-scope="props">
                      <span></span>
                    </template>
                    <template slot="option" slot-scope="props">
                      <Avatar :object="props.option" size="xs"></Avatar>
                      <span class="ml-2">
                        {{ props.option.name }}
                      </span>
                    </template>
                  </multiselect>
                  <div class="form-control auto-height mt-1" v-if="selectedUsers && selectedUsers.length">
                    <div v-for="(user, index) in selectedUsers" class="mt-1 mb-1">
                      <div class="row">
                        <div class="col-sm-1">
                          <font-awesome-icon
                            icon="star"
                            :class="{'color-yellow': user.owner, 'text-light': !user.owner}"
                            class="cursor-pointer"
                            @click="setOwner(index)"
                          />
                        </div>
                        <div class="col-sm-10">
                          <Avatar :object="user" size="xs" :noOwner="true"></Avatar>
                          <span class="ml-2">{{ user.name }}</span>
                        </div>
                        <div class="col-sm-1">
                          <font-awesome-icon
                            icon="times"
                            class="cursor-pointer"
                            @click="removeUser(index)"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <span class="text-light">
                    * {{ $t('generic_controls.create.owners_tip') }}
                  </span>
                </b-form-group>
                <b-form-group>
                  <label qid="generic-controls-type-label">
                    {{ $t('generic_controls.create.control_type_label') }}
                  </label>
                  <multiselect
                      v-model="selectedCategory"
                      :options="categories"
                      :multiple="false"
                      :taggable="false"
                      :select-label="$t('system.dropdown_select_label')"
                      :selected-label="$t('system.dropdown_selected_label')"
                      :deselect-label="$t('system.dropdown_deselect_label')"
                      track-by="value"
                      :placeholder="$t('generic_controls.create.control_type_placeholder')"
                      label="name"
                      qid="generic-controls-type-select"
                  ></multiselect>
                  <span class="text-light">
                    * {{ $t('generic_controls.create.category_tip') }}
                  </span>
                </b-form-group>
                <b-form-group v-if="selectedCategory && selectedCategory.value == 'Control'">
                  <label qid="create-tp-type-label">
                    {{ $t('table.scope') }}
                  </label>
                  <multiselect
                      v-model="selectedScopes"
                      :options="scopes"
                      :multiple="true"
                      track-by="value"
                      :taggable="true"
                      :select-label="$t('system.dropdown_select_label')"
                      :selected-label="$t('system.dropdown_selected_label')"
                      :deselect-label="$t('system.dropdown_deselect_label')"
                      :placeholder="$t('table.scope')"
                      label="name"
                      qid="create-tp-form-type"
                  >
                    <template slot="noResult" slot-scope="props">
                      {{ $t('maintenance.data_subjects.create.subjects_no_results') }}
                    </template>
                  </multiselect>
                </b-form-group>
                <b-form-group>
                  <label qid="generic-controls-type-label">
                    {{ $t('generic_controls.create.category_label') }}
                  </label>
                  <multiselect
                      v-model="selectedTypes"
                      :options="types"
                      :multiple="true"
                      :taggable="false"
                      :select-label="$t('system.dropdown_select_label')"
                      :selected-label="$t('system.dropdown_selected_label')"
                      :deselect-label="$t('system.dropdown_deselect_label')"
                      track-by="value"
                      :placeholder="$t('generic_controls.create.category_placeholder')"
                      label="name"
                      qid="generic-controls-type-select"
                  ></multiselect>
                </b-form-group>
              </div>
            </div>
          </div>
          <div class="text-right mr-4">
            <b-button class="mt-3"
                      variant="secondary"
                      @click="goToList"
                      qid="create-p-cancel-button"
            >
              {{ $t('generic_controls.create.cancel') }}
            </b-button>
            <b-button type="submit"
                      class="mt-3 ml-3"
                      variant="success"
                      :disabled="buttonDisabled"
                      qid="create-p-submit-button"
            >
              <span v-if="buttonDisabled" class="mr-1">
                <font-awesome-icon icon="spinner" size="sm" class="fa-spin"/>
              </span>
              {{ $t('generic_controls.create.submit') }}
            </b-button>
          </div>
        </b-form>
      </div>
    </div>
  </div>
</template>

<script>
  import piincHttp from '@/util/configureAxios.js'
  import Avatar from '@/components/Avatar.vue';

  export default {
    name: 'CreateGenericControl',
    data: function() {
      return {
        breadcrumbItems: [
          {
            text: this.$t('dashboard.title'),
            to: '/'
          },
          {
            text: this.$t('generic_controls.title'),
            to: '/generic-controls'
          },
          {
            text: this.$t('generic_controls.create.title'),
            active: true
          }
        ],
        pForm: {
          name: '',
          description: '',
          control_types: [],
          users: [],
          client:'',
        },
        error: '',
        users: [],
        selectedUsers: [],
        buttonDisabled: false,
        types: [
          { name: this.$t('generic_controls.control_type.preventive'), value: 'preventive' },
          { name: this.$t('generic_controls.control_type.detective'), value: 'detective' },
          { name: this.$t('generic_controls.control_type.corrective'), value: 'corrective' }
        ],
        scopes: [
          { name: this.$t('risk_scenarios.type.process'), value: 'process' },
          { name: this.$t('risk_scenarios.type.activity'), value: 'activity' },
          { name: this.$t('risk_scenarios.type.third_party'), value: 'third_party' }
        ],
        selectedTypes: [],
        categories: [
          { name: this.$t('generic_controls.category.control'), value: 'Control' },
          { name: this.$t('generic_controls.category.security_measure'), value: 'Security measure' }
        ],
        selectedScopes: [],
        selectedCategory: [],
        tags: [],
        selectedTags: [],
        newTags: [],
      }
    },
    computed: {
      getClient() {
        return this.$store.state.client
      }
    },
    watch: {
      // Watch when client changes in store and trigger api reload
      getClient: function () {
        this.loadUsers()
        if (this.scopes && this.scopes.length == 3) {
          if (this.getClient.systemModule) {
            this.scopes.push({ name: this.$t('risk_scenarios.type.system'), value: 'system' },)
          }
          if (this.getClient.policyModule) {
            this.scopes.push({ name: this.$t('risk_scenarios.type.policy'), value: 'policy' },)
          }
        }
      }
    },
    methods: {
      goToList() {
        this.$router.push('/generic-controls')
      },
      addTag(newTag) {
        const tag = {
          name: newTag,
          translatable: newTag,
          name_translated: newTag
        }
        this.selectedTags.push(tag)
        this.newTags.push(tag)
      },
      removeUser(index) {
        let selectedItem = this.selectedUsers[index]
        selectedItem.owner = false;
        this.$set(this.selectedUsers, index, selectedItem)
        // Remove item from selected users array
        this.selectedUsers.splice(index, 1);
      },
      removeUserFromDropdown(item) {
        item.owner = false;
      },
      setOwner(index) {
        let selectedItem = this.selectedUsers[index]
        _.forEach(this.selectedUsers, function (item) {
          item.owner = false;
        })
        selectedItem.owner = true;
        this.$set(this.selectedUsers, index, selectedItem)
      },
      formSubmit(evt) {
        this.buttonDisabled = true;
        let formData = new FormData();
        let files = document.querySelector('#file');
        let selectedOwner = false;

        // Keep the reference in a variable
        // to use it inside function called when promise returned
        let self = this;
        evt.preventDefault();

        // Append list of users to FormData
        if (this.selectedUsers && this.selectedUsers.length) {
          for (var i = 0; i < this.selectedUsers.length; i++) {
            formData.append("users[]", this.selectedUsers[i].id);
            if (this.selectedUsers[i].owner) {
              selectedOwner = true;
              formData.append("owner", this.selectedUsers[i].id);
            }
          }
        } else {
          selectedOwner = true;
        }

        // Append list of countries to FormData
        if (this.selectedTypes && this.selectedTypes.length) {
          for (var i = 0; i < this.selectedTypes.length; i++) {
            formData.append("control_types[]", this.selectedTypes[i].value);
          }
        }

        // Append list of types
        if (this.selectedScopes && this.selectedScopes.length && this.selectedCategory.value == 'Control') {
          for (var i = 0; i < this.selectedScopes.length; i++) {
            formData.append("scopes[]", this.selectedScopes[i].value);
          }
        }

        if (this.selectedTags && this.selectedTags.length) {
          for (var i = 0; i < this.selectedTags.length; i++) {
            formData.append("tags[]", this.selectedTags[i].name_translated);
          }
        }

        formData.append("add_description", 'Optional');
        formData.append("add_attachments", 'Optional');

        if (this.selectedCategory && this.selectedCategory.value) {
          formData.append("type", this.selectedCategory.value);
        }

        formData.append("name", this.pForm.name);
        formData.append("description", this.pForm.description);
        formData.append("client", this.getClient.slug);

        if (selectedOwner && this.selectedCategory && this.selectedCategory.value && ((this.selectedCategory.value == 'Control' && this.selectedScopes.length) || this.selectedCategory.value == 'Security measure')) {
          piincHttp.post('/generic-controls', formData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          }).then(function(response) {
            self.$toastr('success', self.$t('generic_controls.create.success'))
            self.buttonDisabled = false;
            self.$router.push({ name: 'generic_controls.view', params: { slug: response.data.slug }})
          }, function(error) {
            if (error.status !== 200) {
              self.buttonDisabled = false;
              self.error = error.data.message;
              // Print whole array of actual errors beside message
              self.error = self.error + '\n\n' + JSON.stringify(error.data.errors);
            }
          })
        } else {
          this.error = '';
          if (!selectedOwner) {
            this.error += this.$t('system.owners_error_message')
          }

          if (this.selectedCategory && this.selectedCategory.value == 'Control' && !this.selectedScopes.length) {
            this.error +=  ' ' + this.$t('system.scope_error_message')
          }

          if (!this.selectedCategory || !this.selectedCategory.value) {
            this.error +=  ' ' + this.$t('system.category_error_message')
          }

          this.buttonDisabled = false;
        }
      },
      loadTags(query) {
        let self = this;
        piincHttp.get('tags', { params:
          {
            client: this.getClient.slug,
            keyword: query ? query : undefined
          }
        }).then(function(response) {
          self.tags = response.data
          for (var i = 0; i < self.newTags.length; i++) {
            self.tags.push(self.newTags[i]);
          }
          if (self.tags && !self.tags.length && !query) {
            self.tags.push({name_translated: self.$t('system.no_results'), $isDisabled: true})
          }
        }, function() {});
      },
      loadUsers(query) {
        let self = this;
        piincHttp.get('users', { params:
          {
            client: this.getClient.id,
            keyword: query ? query : undefined,
            per_page: 50
          }
        }).then(function(response) {
          self.users = response.data.items
          if (self.users && !self.users.length && !query) {
            self.users.push({name: self.$t('system.no_results'), $isDisabled: true})
          }
        }, function() {});
      },
    },
    components: {
      Avatar
    },
    created () {
      if (this.getClient && this.getClient.slug) {
        this.loadTags()
        this.loadUsers()

        if (this.getClient.systemModule) {
          this.scopes.push({ name: this.$t('risk_scenarios.type.system'), value: 'system' },)
        }
        if (this.getClient.policyModule) {
          this.scopes.push({ name: this.$t('risk_scenarios.type.policy'), value: 'policy' },)
        }
      }
    }
  }
</script>
